
:root {
  --primary-color: #202e80;
  --secondary-color:#749beb;
  --primary-color-opacity:#2579adf5;
  --primary-color-opacity-strong:#000000ab;
  --primary-color-opacity-strong-alt:#0000007a;
  --primary-color-opacity-dark:#000000e6;
  --primary-color-opacity-blue:#202e809a;
  --main-color: #ffffff;
  --main-color-opacity: #ffffff6b;
  --main-text-color: #ffffff;
  /* --secondary-color: #adadad; */
}


body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--primary-color); */
  background-color: black;
  color:var(--main-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

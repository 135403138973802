
.App {
  text-align: center;
  font-family: 'Montserrat',sans-serif;
}

input {
  padding:10px !important;
}
p{
  line-height: 1.2rem !important;
}
.menu-item.home-mobile{
  display: none;
}
.flex {
  display:flex;
}
.flex-row {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flex-row-start {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-row-end {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.flex-row-top {  
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-column {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex-column-start {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-reverse {
  flex-direction: row-reverse;
}
.bold{
  font-weight: 800;
}
.lite-bold{
  font-weight: 600 !important;
}
.active{
  border-bottom: solid 2px white;
}
.h1{
  font-size: 1.618rem;
  font-weight: 600;
  color:var(--secondary-color);
}

.h2{
  font-size: 1.618rem;
  font-weight: 600;
  color:var(--secondary-color);
  text-transform: uppercase;
}

.white{
  color:white !important;
}
.h3{
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color:var(--secondary-color);
}
.h3n{
  font-size: 1rem;
  font-weight: 600;
  color:var(--secondary-color);
}

.h3l{
  font-size: 1rem;
  font-weight: 400;
  color:var(--secondary-color);
}


.h4{
  font-size: 1.1rem;
  color:var(--main-text-color);
}
.link{
  cursor:pointer;
}
.link:hover {
  opacity:0.6;
}
.anwser{
  font-size: 0.9rem;
}

.ant-spin-dot-holder{
  color:white !important;
}

.menu-header{
  position: fixed;
  height: 100px;
  width: 100vw;
  z-index: 10;
  background-color: var(--primary-color);
}

.menu-container{
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 85%;
  margin:0 5%;
  align-items: center;
}

.menu-items{
  display: flex;
  justify-content: space-between;
  flex-direction: row; 
}

.menu-item{
  padding:10px 0;
  margin:0;
  text-transform: uppercase;
  font-weight: 500;
  cursor:pointer;
  /* min-width: 170px; */
  margin: 0 16px;
}
.menu-item.mobile{
  display: none;
}
.menu-item.active{
  background-color: #cccccc;
}
.menu-mobile{
  display: none;
}
.language-button{
  cursor:pointer;
  margin-left: 16px;
  font-weight: 500;
}

.menu-button{
  background-color: var(--main-color);
  color:var(--primary-color);
  border-radius:6px;
  border:solid 1px white;
  margin-left: 16px;  
  font-weight: 800;
  padding: 10px;
}
.menu-button:hover{
  background-color:var(--main-color-opacity-strong);
  color:var(--main-text-color);
  border:solid 1px white;
}
.faq-block{
margin-top: 32px;
}
.logo{
  width: 100px !important;
  height: 63px !important;
}

.logo-big{
  width: 200px !important;
  height: 126px !important;
}

.lang-switcher {
  width: 60px;
  display: flex;
  justify-content: space-between;
}
.spinner{
  padding-top: 333px;
}
.page-container{
  padding-top: 100px;
  width: 100%;
}

.content-container{
  position: absolute;
  z-index: 1;
  width: 100%;
  overflow-x: hidden;
}

.home-header-block{
  width: 100%;
  margin:0;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-card {
  width: 100%;
  margin-bottom:32px;
}
.home-card.contact{
  background-color: var(--primary-color-opacity-dark);
  margin-bottom:0;
}

.foot-card {
  width: 100%;
  margin-bottom:32px;
  background-color: var(--primary-color-opacity-dark);
  margin-bottom:0;
}
.foot-card.contact {
  /* margin-top: 16px; */
}

.home-card-container{
  width: 85%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: var(--primary-color-opacity-strong);
  margin:0 auto;
  padding:32px;
  /* padding:40px; */
  border-radius:16px;
}
.home-card.contact .home-card-container{
  background-color: transparent;
}
.home-block{
  width: 100%;
  background-color: var(--primary-color-opacity-strong); 
  /* margin:32px 0; */
}
.page-block{
  width: 100%;
  padding-bottom: 32px;
  background-color: var(--primary-color-opacity-strong); 
  /* margin:32px 0; */
}
.home-block-footer{
  margin:0 !important;
}
.home-block-container{
  width: 85%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: var(--primary-color-opacity-strong);
  margin:0 auto;
  padding:32px 0;
  /* padding:40px; */
  /* border-radius:16px; */
}

.page-block-container{
  width: 85%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: var(--primary-color-opacity-strong-alt);
  margin:0 auto;
  padding:32px 0;
  padding: 32px;
  border-radius: 16px;
}
.page-block-container.detail{
  padding:0;
  padding:0;
  border-radius: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}


.home-card-container.contact{
  background-color: transparent;
}

.home-header-block-container{
    /* height: 600px; */
    width:800px !important;
    padding:40px;
    border-radius:16px;
    background-color: var(--primary-color-opacity-strong);
    width: 85%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: var(--primary-color-opacity-strong);
    margin:0 auto;
    padding:32px;
}

.home-block-container.footer{
  margin:0;
  width: 100%;
  padding:32px 0;
  background-color: transparent;
  width: 100% !important;
  max-width: unset;
}

.home-block-content{
  margin-top: 16px;
}

.mosaic-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns */
  gap: 16px; /* Gap between grid items */
}

.mosaic-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 16px; /* Gap between grid items */
}

.service-container {  
  padding: 16px; 
  text-align: center;
  justify-content: flex-start;
}

.service-icon{
  border-radius: 60px;
  background-color: white;
  width: 111px;
  height: 111px;
  margin-bottom: 16px;
}

.footer-block{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding:16px;
  width: 100%;
}

.breadcrumb{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  font-weight: 300;
  width: 85%;
  max-width: 1000px;
  margin:0 auto;
  font-size: 1.2rem;

}
.breadcrumb-separator{
 margin:0 10px;
}
.breadcrumb-title{
  color: var(--secondary-color);
}
.client-case-detail-block{
  padding:32px;
}
.client-cases-home-img {
  border-radius:16px;
  cursor:pointer;
  width:400px;
  height:400px;
  object-fit: cover;
}

.client-cases-list-img {
  border-radius:6px;
  cursor:pointer;
  width:300px;
  height:300px;
  object-fit: cover;
}
.flex-reverse .client-cases-list-img{
  margin-right: 16px;
}

.client-cases-detail-img{
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.button-main {
  border:solid 1px white;
  border-radius:6px;
  padding:6px 16px;
}
/* .button-main:hover{
  background-color: var(--secondary-color);
  border:0;
} */

.arrow-container {
  position: relative;
  bottom: 100px;
  left: 100%;
  transform: translateX(-50%);
  z-index: 1000;
}

.arrow {
  width: 64px;
  height:64px;
  /* border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 30px solid white; */
  animation: bounce 2s infinite;
}
.client-case-list-block{
  margin:0 32px;
}
.mobile-player{
  display: none;
}
.service-pager div{
  width: 30%;
  text-align: center;
}
.service-pager div.lefter{
  text-align: left;
}
.service-pager div.righter{
  text-align: right;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


@media (min-width: 601px) and (max-width: 1023px) {
  /* CSS rules for devices with a width between 601px and 1023px ( tablet devices) */
}


@media (max-width: 600px) {
  .arrow-container,
  .menu-items{
    display:none;
  }  
  .mosaic-grid-3 {
    grid-template-columns: repeat(2, 1fr);
    gap:0;
  }
  .mosaic-grid-2 {
    grid-template-columns: repeat(1, 1fr);
    /* gap: 16px; */
  }

  .client-cases-home-img {
    width:100%;
    height: 300px;
  }
  
  .client-case-list-block{
    margin:32px 0;
  }
  .flex-row-top {
    flex-direction: column;
  }
  .flex-row.mobile{
    flex-direction:column-reverse;
  }
  .page-block-container div:not(.margin-mobile){
    margin-left: 0 !important;
  }
  .footer-block {
    width: initial;
    margin-left: 16px;
  }
  .footer-block img,
  .footer-block div {
    margin-left:0 !important;
  }

  .home-header-block{
    width: 100%;
    margin-top:20px;
    height: 100%;
  }
  .home-card-container,
  .home-header-block-container {
    border-radius: 0;    
  }
  .home-header-block-container {
    width: 80% !important;
    border-radius: 6px;    
}
  .service-block{
    flex-direction: column;
    margin-bottom: 32px !important;
    text-align: left;
  }
  .flex-colum.service-container,
  .flex-colum.client-case{
    margin-bottom: 32px !important;
  }
  .margin-mobile{
    margin-left: 32px !important;
  }
  .menu-container img {
    margin:0 !important;
  }
  .menu-mobile{
    display: inline;
  }
  .menu-mobile .menu-items{
    display: none;
    position: fixed;
    top: 100px;
    left: 0;
    background-color: var(--primary-color);
    width: 100%;
    text-align: left;
    padding: 32px 0;
  }
  .menu-button{
    width: 60%;
    margin: 16px 12% 0 12%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-button:hover{
    background-color: white;
  }
  .menu-item.home-mobile{
    display: block;
    margin:32px auto;
  }
  .service-container {
    padding: 10px;
  }
  .flex-reverse {
    flex-direction: column-reverse;
  }
  .flex-reverse .client-cases-list-img{
    margin-right: 0;
  }
  .client-case-detail-block {
    padding: 16px;
  }
  .link:hover {
    opacity:1;
  }
  .video-player{
    display: none;
  }
  .mobile-player{
    display: block;
  }
  .h2{
    font-size: 1.318rem;
  }
  .service-pager{
    flex-direction: row !important;
  }
  .h3l{
    font-size: 0.8rem;
  }
}

